<template>
  <Image
    :src="colorTileImage"
    alt="Colorpicker"
    img-classes="object-cover bg-white"
    class="float-left"
    :class="colorInputClasses"
  />
  <input
    type="color"
    v-model="color"
    class="absolute cursor-pointer"
    :class="colorInputClasses"
    @input="onColorInput"
    @focus="onColorComplete"
  />
</template>

<script setup lang="ts">
import colorTileImage from "@public/images/colorTile.png";
import { Image } from "prism";
import { computed, ref } from "vue";
import debounce from "lodash.debounce";
import Flipper from "@/modules/flipper";

const color = ref("#0000FF");

const emit = defineEmits<{
  (e: "colorPickerChanged", color: string): void;
  (e: "colorPickerChangeComplete", color: string): void;
}>();

// Emits the event continuously as the color changes
const onColorInput = () => {
  emit("colorPickerChanged", color.value);
  debouncedEmitColorComplete();
};

const onColorComplete = () => {
  emit("colorPickerChangeComplete", color.value);
};

const debouncedEmitColorComplete = debounce(() => {
  onColorComplete();
}, 300);

const isNewLayoutEnabled = Flipper.isEnabled("background_image_search");
const colorInputClasses = computed(() => ({
  "!w-16 !h-16": !isNewLayoutEnabled,
  "!h-20 !w-20": isNewLayoutEnabled,
}));
</script>

<style lang="css" scoped>
input[type="color"] {
  opacity: 0;
  display: block;
  width: 32px;
  height: 32px;
  border: none;
}
#color-picker-wrapper {
  float: left;
}
</style>
