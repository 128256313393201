<template>
  <Transition name="fade">
    <div id="alert-banner" v-if="banner?.name" class="w-full z-50">
      <Alert class="rounded-none" :onClose="close" :tone="banner.tone" dismissable hideIcon>
        <span class="block" v-html="banner.html"></span>
      </Alert>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { Alert } from "prism";
import Rails from "@rails/ujs";
import Routes from "@/modules/routes";
import Configs from "@/modules/configs";
import { onMounted, ref } from "vue";

interface AlertBannerConfig {
  name: string;
  slug: string;
  html: string;
  tone?: string;
  remember_cta_action?: boolean;
}

onMounted(() => {
  banner.value = Configs.get("banner") as AlertBannerConfig;
});

const banner = ref(null);

const close = async () => {
  if (banner.value.remember_cta_action) {
    await fetch(Routes.get("session_preferences_url"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify({
        key: `${banner.value.name}_dismissed`,
        value: {
          [banner.value.slug]: true,
        },
      }),
    });
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
  transition-delay: 1s;
}
.fade-enter-from,
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
