<template>
  <div v-if="!props.hasError" class="md:hidden fixed bottom-0 w-full !bg-white !bg-opacity-90 !backdrop-blur-lg">
    <div class="mobile-buttons-wrapper">
      <div
        id="mobile-buttons"
        ref="buttonsContainer"
        class="flex my-2 pr-1.5"
        @scroll="checkScrollEnd"
        :class="{ 'blur-hidden': scrollHasReachedEnd || !scrollVisible }"
      >
        <template>
          <ActionButton
            class="sm:!flex-col"
            tone="primary"
            v-if="
              !hasImagePreviewErrored(editorStore) &&
              !props.isProcessing &&
              props.editorStore.selectedImage &&
              props.editorStore.selectedImage.meta.preview
            "
            @click="
              () => {
                props.downloadPreview();
              }
            "
            :loading="props.isProcessingPreviewDownload"
          >
            <template #icon>
              <DownloadIcon />
            </template>
            <template #title
              ><span class="text-3xs pt-1">{{ I18n.t("editor.download") }}</span></template
            >
          </ActionButton>
          <div class="flex gap-1 items-start justify-center cursor-wait" v-else>
            <div class="h-11 w-11 rounded-full !bg-secondary animate-pulse"></div>
          </div>
        </template>

        <template>
          <MobileHdDownload
            v-if="!props.isProcessing && showMobileDownloadHdIcon(props.editorStore)"
            :name="imagePreviewName"
            :editor="props.editor"
            :is-processing-hd-download="props.isProcessingHdDownload"
            :credits="props.credits"
            :download-hd="props.downloadHd"
            :load-credits-and-update-preview="props.loadCreditsAndUpdatePreview"
            :show-button="!props.isProcessing && showMobileDownloadHdIcon(props.editorStore)"
            :is-ios-disabled="isImageTooLargeForIos"
          />
          <div class="flex gap-1 items-start justify-center cursor-wait" v-else>
            <div class="h-11 w-11 rounded-full !bg-secondary animate-pulse"></div>
          </div>
        </template>

        <!-- Add Background Button-->
        <div v-if="props.isProcessing" class="flex gap-1 items-start justify-center cursor-wait">
          <div class="h-11 w-11 rounded-full !bg-secondary animate-pulse"></div>
        </div>
        <ActionButton
          v-else
          class="sm:!flex-col"
          @click="props.togglePanel('addBackground')"
          :badgeText="Flipper.isEnabled('background_image_search') ? capitilizeString(I18n.t('misc.new_badge')) : ''"
        >
          <template #icon>
            <AddIcon
              v-if="
                !props.currentPersistentStore?.selectedBackgroundPhotoUrl &&
                !props.currentPersistentStore?.selectedBackgroundColor
              "
            />
            <span v-else class="w-full h-full" :style="props.backgroundActionButtonStyle" />
          </template>
          <template #title
            ><span class="text-3xs pt-1">{{ I18n.t(`editor.background`) }}</span></template
          >
        </ActionButton>

        <!-- Brush Button -->
        <div v-if="props.isProcessing" class="flex gap-1 items-start justify-center cursor-wait">
          <div class="h-11 w-11 rounded-full !bg-secondary animate-pulse"></div>
        </div>
        <span
          v-else-if="hasExceededMagicBrushIterations(props.editorStore.selectedImage, props.refreshTippy)"
          class="hover-tooltip flex justify-center"
          :data-tippy-content="`${I18n.t('ai_brush.error.iterations_exceeded')}`"
          data-tippy-placement="bottom"
        >
          <ActionButton
            class="sm:!flex-col"
            :disabled="hasExceededMagicBrushIterations(props.editorStore.selectedImage, props.refreshTippy)"
          >
            <template #icon>
              <BrushIcon />
            </template>
            <template #title
              ><span class="text-3xs pt-1">{{ I18n.t(`editor.erase_restore`) }}</span></template
            >
          </ActionButton>
        </span>

        <template v-else>
          <ActionButton class="sm:!flex-col" @click="props.togglePanel('eraseRestore')">
            <template #icon>
              <BrushIcon />
            </template>
            <template #title
              ><span class="text-3xs pt-1">{{ I18n.t(`editor.erase_restore`) }}</span></template
            >
          </ActionButton>
        </template>
        <!-- Effects button -->
        <template v-if="Flipper.isEnabled('effects_panel')">
          <div v-if="props.isProcessing" class="flex gap-1 items-start justify-center cursor-wait">
            <div class="h-11 w-11 rounded-full !bg-secondary animate-pulse test"></div>
          </div>
          <ActionButton v-else class="sm:!flex-col" @click="props.togglePanel('fx')">
            <template #icon>
              <FxIcon />
            </template>
            <template #title
              ><span class="text-3xs pt-1">{{ I18n.t(`editor.apply_effects`) }}</span></template
            >
          </ActionButton>
        </template>

        <!-- Canva CTA -->
        <span>
          <div v-if="props.isProcessing" class="flex gap-1 items-start justify-center cursor-wait">
            <div class="h-11 w-11 rounded-full !bg-secondary animate-pulse"></div>
          </div>
          <CanvaCta
            v-else
            :getDataURL="props.getDataURL"
            :stage="props.stage"
            :stageHd="props.stageHd"
            :image="props.editorStore.selectedImage"
            :reset-zoom="props.resetZoom"
            :refresh-all-hd-layers="props.refreshAllHdLayers"
            :persistent-store="props.currentPersistentStore"
          />
        </span>
      </div>
      <div class="scroll-indicator" :class="{ visible: !scrollHasReachedEnd && scrollVisible }">
        <ChevronRightIcon class="text-secondary-active" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ProcessingState, Image, hasExceededMagicBrushIterations } from "@/modules/internal_api/image";
import { EditorStoreSGA, Panel } from "@/stores/editor_store";
import { PersistentStore } from "@/stores/persistent_store";
import { StyleValue, computed, inject, onMounted, onUnmounted, ref, watch } from "vue";
import { useCheckIfIOSImageTooLarge } from "@/composables/check_ios_image_too_large";
import { ActionButton, BrushIcon, DownloadIcon, AddIcon, FxIcon, ChevronRightIcon } from "prism";
import Flipper from "@/modules/flipper";
import MobileHdDownload from "@/components/upload/mobile_hd_download.vue";
import CanvaCta from "@/components/canva_cta.vue";
import { Stage } from "konva/lib/Stage";
import { capitilizeString } from "@/modules/utils";
import Split from "@/modules/split";

interface Props {
  stage: Stage;
  stageHd: Stage;
  credits: number;
  editor: HTMLElement;
  isProcessing: boolean;
  isProcessingPreviewDownload: boolean;
  isProcessingHdDownload: boolean;
  hasError: boolean;
  editorStore: EditorStoreSGA;
  currentPersistentStore: PersistentStore;
  backgroundActionButtonStyle: StyleValue;
  downloadPreview: (force_share?: boolean) => Promise<void>;
  downloadHd: (closeDialog?: () => void) => Promise<void>;
  loadCreditsAndUpdatePreview: () => Promise<void>;
  refreshTippy: () => void;
  togglePanel: (id: Panel) => Promise<void>;
  getDataURL: (image: Image, stage: any, element: any) => string;
  resetZoom: () => void;
  refreshAllHdLayers: (image: Image) => Promise<void>;
}

const I18n = inject("I18n");
const props = defineProps<Props>();
const isImageTooLargeForIos = useCheckIfIOSImageTooLarge();

const scrollHasReachedEnd = ref(false);
const scrollVisible = ref(false);
const buttonsContainer = ref<HTMLDivElement>();

const checkScrollEnd = (event: Event): void => {
  const { scrollWidth, scrollLeft, clientWidth } = event.target as HTMLElement;
  const tolerance = 10;
  scrollHasReachedEnd.value = scrollWidth - scrollLeft - clientWidth <= tolerance;
};

const hasHorizontalScroll = () => {
  if (buttonsContainer.value) {
    return buttonsContainer.value.scrollWidth > buttonsContainer.value.clientWidth;
  }
  return false;
};

const checkScroll = () => {
  // Trigger Vue's reactivity system by accessing the computed property
  scrollVisible.value = hasHorizontalScroll();
};

const hasImagePreviewErrored = (store: EditorStoreSGA) => store.selectedImage.previewResult.state === "error";

watch([() => props.isProcessing], () => {
  // Scroll reset when there is image uploaded
  buttonsContainer.value.scrollLeft = 0;
});

const showMobileDownloadHdIcon = (store: EditorStoreSGA) =>
  !!store.selectedImage && !hasImagePreviewErrored(store) && !!store.selectedImage.meta.preview;

const imagePreviewName = computed(() => {
  const image: Image = props.editorStore.selectedImage;
  if (image.previewResult.state !== ProcessingState.Finished) {
    return;
  }

  return image.previewResult.name;
});

onMounted(() => {
  window.addEventListener("resize", checkScroll);
  checkScroll();
});

onUnmounted(() => {
  window.removeEventListener("resize", checkScroll);
});
</script>
<style scoped lang="scss">
#mobile-buttons {
  align-items: center;
  justify-content: center;
  padding: 5px 0;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    min-width: 72px;
  }

  .btn-hd-download {
    background: rgb(234 241 255 / var(--tw-bg-opacity));
    color: rgb(25 93 243 / var(--tw-bg-opacity));

    &:active {
      background: rgb(173 199 255 / var(--tw-bg-opacity));
    }

    &:hover {
      background: rgb(193 213 255 / var(--tw-bg-opacity));
      scale: 1.08;
      transition: scale ease-in-out 0.2s;
    }
  }

  @media (min-width: 480px) {
    & {
      gap: 1rem;
      justify-content: center;
    }
  }

  @media (max-width: 420px) {
    & {
      overflow-x: scroll;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &::after,
    &::before {
      position: absolute;
      height: 100%;
      width: 3rem;
      content: "";
      top: 0;
      right: 0;
      pointer-events: auto;
      opacity: 1;
      background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0.75) 70%, rgba(255, 255, 255, 0) 100%);
      transition: opacity 150ms;
      transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
    }
  }

  @media (max-width: 375px) {
    & {
      gap: 0;
    }
  }

  @media (max-width: 768px) {
    .text-3xs {
      font-size: 0.5rem !important;
      line-height: 0.8rem !important;
    }
  }
}

.mobile-buttons-wrapper {
  position: relative;
}

.scroll-indicator {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  position: absolute;
  top: -0.5rem;
  right: 0.5rem;
  opacity: 0;
  transition: opacity 150ms;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
  svg {
    height: 20px;
  }
}

.blur-hidden::after,
.blur-hidden::before {
  opacity: 0 !important;
  pointer-events: none !important;
}
@media (max-width: 420px) {
  .visible {
    opacity: 1;
  }
}
</style>
