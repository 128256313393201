<template>
  <div class="flex flex-wrap justify-center">
    <template v-for="collection in props.collections" :key="collection.id">
      <Button
        v-if="!!collectionName(collection.title)"
        variant="outline"
        size="compact"
        as="button"
        class="mr-2 mb-2"
        @click="selectCollection(collection)"
      >
        {{ collectionName(collection.title) }}
      </Button>
    </template>
  </div>
</template>

<script setup lang="ts">
import { BackgroundImageCollection } from "@/modules/internal_api/background_search";
import { rbgEditorBackgroundPexelsCollectionClickedV100 } from "kaleido-event-tracker";
import { Button } from "prism";

interface CollectionsProps {
  collections: BackgroundImageCollection[];
  foregroundImageId: string | null;
}

const props = defineProps<CollectionsProps>();

const emit = defineEmits<{
  (e: "selectCollection", value: BackgroundImageCollection): void;
}>();

const selectCollection = (collection: BackgroundImageCollection) => {
  rbgEditorBackgroundPexelsCollectionClickedV100({
    image_id: props.foregroundImageId,
    collections_displayed: props.collections.map((c) => c.key),
    collection_clicked: collection.key,
  });
  emit("selectCollection", collection);
};

const collectionName = (title) => {
  return I18n.t(`editor.background_image_search.collections.${title}`, { defaultValue: '' })
}

</script>

<style scoped></style>
