<template>
  <ImageButton
    :size="props.imageButtonSize"
    :elevate-on-hover="false"
    :small-on-mobile="false"
    :selected="isSelected(null)"
    class="justify-center items-center"
    @click="() => select(null)"
  >
    <div class="bg-white flex justify-center items-center !w-full !h-full">
      <DoNotEnterIcon />
    </div>
  </ImageButton>

  <ImageButton
    :size="props.imageButtonSize"
    :elevate-on-hover="false"
    :small-on-mobile="false"
    :selected="nativeColorPickerSelected"
    class="justify-center items-center"
  >
    <NativeColorPicker
      v-on:color-picker-change-complete="select"
      v-on:color-picker-changed="setCurrentColor"
    ></NativeColorPicker>
  </ImageButton>

  <template v-for="color in props.colors">
    <ImageButton
      :size="props.imageButtonSize"
      :elevate-on-hover="false"
      :small-on-mobile="false"
      :selected="isSelected(color)"
      class="justify-center items-center"
      @click="() => select(color)"
    >
      <div class="!w-full !h-full" :style="{ backgroundColor: color }" />
    </ImageButton>
  </template>
</template>

<script setup lang="ts">
import NativeColorPicker from "../prism/native_color_picker.vue";
import { DoNotEnterIcon, ImageButton } from "prism";
import { computed, inject } from "vue";
import { useEditorStore } from "@/stores/editor_store";
import { rbgEditorApplyBackgroundColorV102 } from "kaleido-event-tracker";
import { PersistentStore } from "@/stores/persistent_store";

export interface ColorTilesProps {
  selectedImageState: PersistentStore;
  colors: string[];
  imageButtonSize: string;
}

const store = useEditorStore();

// native color picker tile is only selected if there is a color set that is not in the colorList
const nativeColorPickerSelected = computed(() => {
  if (props.colors.includes(props.selectedImageState?.selectedBackgroundColor)) return false;
  if (props.selectedImageState?.selectedBackgroundColor == "#ffffff") return false;
  if (props.selectedImageState?.selectedBackgroundColor) return true;
});

const props = withDefaults(defineProps<ColorTilesProps>(), {
  imageButtonSize: "default",
});
const tempBackgroundColor = inject<{
  updateTempBackgroundColor: (string) => void;
}>("tempBackgroundColor");

const resetBackgroundImage = () => {
  props.selectedImageState.setSelectedBackgroundPhoto(undefined);
  props.selectedImageState.setBackgroundBlurEnabled(false);
  props.selectedImageState.setSelectedSearchedBackground(false);
};

const setCurrentColor = (color: string) => {
  tempBackgroundColor.updateTempBackgroundColor(color);
  if (props.selectedImageState.selectedBackgroundPhotoUrl || props.selectedImageState.isBackgroundBlurEnabled) {
    props.selectedImageState.withSnapshot(() => {
      resetBackgroundImage();
    });
  }
};

const select = (color: string) => {
  tempBackgroundColor.updateTempBackgroundColor(null);
  props.selectedImageState.withSnapshot(() => {
    resetBackgroundImage();
    props.selectedImageState.setSelectedBackgroundColor(color);
  });

  rbgEditorApplyBackgroundColorV102({
    image_id: store.selectedImage.meta.id,
    color_value: color,
  });
};

const isSelected = (color) => {
  return props.selectedImageState.selectedBackgroundColor === color;
};
</script>

<style>
input[type="color"] {
  opacity: 0;
  display: block;
  width: 32px;
  height: 32px;
  border: none;
}
#color-picker-wrapper {
  float: left;
}
</style>
