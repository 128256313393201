<template>
  <Drawer :on-close="closePanel" close-icon-position="floating" :close-title="I18n.t('editor.modal_close')">
    <template #content>
      <div class="flex md:flex-col flex-col px-4 pt-4 pb-0 md:!p-0 content-wrapper">
        <TabGroup>
          <TabList
            v-if="!store.isBackgroundSearchActive"
            class="flex space-x-2 !pl-3 md:!pl-0 md:!pt-4 md:!pb-2 justify-start"
            key="tabList"
          >
            <Tab v-slot="{ selected }" as="template">
              <TabButton class="min-w-[5rem]" :selected="selected">{{ I18n.t("editor.photo") }}</TabButton>
            </Tab>
            <Tab v-slot="{ selected }" as="template">
              <TabButton class="min-w-[5rem]" :selected="selected">{{ I18n.t("editor.color") }}</TabButton>
            </Tab>
          </TabList>

          <TabPanels key="panels">
            <!-- Start Photo -->
            <TransitionGroup name="slide">
              <TabPanel>
                <BackgroundImageSearch
                  :foreground-type="props.foregroundType"
                  :selected-image-state="props.selectedImageState"
                />
              </TabPanel>
            </TransitionGroup>
            <!-- End Photo -->

            <!-- Start Color -->
            <TabPanel>
              <div class="divider"><hr /></div>
              <div class="scroll-container">
                <div class="sm:h-64 pb-4 gap-1 tile-grid-photos-new-layout">
                  <ColorTiles
                    :selected-image-state="props.selectedImageState"
                    :colors="availableColors"
                    image-button-size="lg"
                  />
                </div>
              </div>
              <div class="hidden md:block shadow-fold w-full h-2"></div>
            </TabPanel>
            <!-- End Color -->
          </TabPanels>
        </TabGroup>
      </div>
    </template>
  </Drawer>
</template>

<script setup lang="ts">
import "@/src/i18n";

import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import { TabButton, Drawer } from "prism";
import ColorTiles from "../prism/color_tiles.vue";
import BackgroundImageSearch from "./add_background/background_image_search.vue";
import { ref, inject } from "vue";
import { PersistentStore } from "@/stores/persistent_store";
import { useEditorStore } from "@/stores/editor_store";

const I18n = inject("I18n");

const store = useEditorStore();

interface AddBackgroundProps {
  foregroundType: string;
  selectedImageState: PersistentStore;
}

const props = defineProps<AddBackgroundProps>();
const availableColors = ref<string[]>([
  "#ffffff",
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#9e9e9e",
  "#607d8b",
  "#000000",
]);

const emit = defineEmits<{
  (e: "closePanel"): void;
}>();

const closePanel = () => {
  emit("closePanel");
};
</script>

<style scoped>
.tile-grid-photos-new-layout {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media (min-width: 368px) and (max-width: 767px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.content-wrapper {
  max-height: 29rem;
  width: 100%;

  @media (min-width: 768px) {
    width: 16.5rem;
  }

  @media (max-width: 768px) {
    height: 24rem;
  }
}

.slide-move {
  transition: all 0.15s ease;
}
.slide-leave-active {
  position: absolute;
}

.divider {
  margin: 0 -16px !important;

  @media (min-width: 640px) {
    margin: 0 -24px !important;
  }
}

.scroll-container {
  overflow-x: hidden;
  overflow-y: auto;

  @media (max-width: 768px) {
    height: 18rem;
  }
}

/* this feature is only supported on ios, it's used here because scroll behavior is different on ios and negative margin shifts the content */
@supports not (-webkit-touch-callout: none) {
  .scroll-container {
    margin-right: -1rem;
  }
}

.scroll-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1rem;
}

.scroll-container::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
</style>
