import thumbnailPlaceholder from "@assets/images/inline-editor/thumbnail_placeholder.jpg";
import originalPlaceholder from "@assets/images/inline-editor/original_placeholder.jpg";
import { ExportResult, ZipCanvas } from "@/modules/zip_canvas";
import { getNestedKey } from "../utils";
import { Point } from "@/stores/line";

export const MAX_MAGIC_BRUSH_ITERATIONS = 10;

export enum ProcessingState {
  Ready = "ready",
  Processing = "processing",
  Error = "error",
  Finished = "finished",
}

export enum ImageOrientation {
  Landscape = "landscape",
  Portrait = "portrait",
  Square = "square",
}

export enum UploadState {
  Pending = "pending",
  Uploading = "uploading",
  Error = "error",
  Finished = "finished",
  TokenRequested = "token_requested",
}

export enum ResultVariant {
  Preview = "previewResult",
  Hd = "hdResult",
  MagicBrushPreview = "magicBrushPreviewResult",
  MagicBrushHd = "magicBrushHdResult",
}

export type ResultData = {
  state: ProcessingState;
  orderedAt: Date | null;
  url?: string;
  name?: string;
  layers?: ResultLayers;
  rated?: boolean;
  rating?: number;
  contributed?: boolean;
  nextFetchIn?: number;
};

export type ResultLayers = {
  foreground: ExportResult;
  semiTransparency: ExportResult;
  shadow: ExportResult;
};

export type ProcessingError = {
  state: ProcessingState.Error;
  code: string;
  message: string;
};

export type UploadError = {
  state: UploadState.Error;
  code: string;
  message: string;
};

export type ImageDimensions = {
  width: number;
  height: number;
};

export type IterationHistory = {
  [key: string]: {
    preview: string;
    hd: string;
    alpha: string;
  };
};

export type ImageMetaData = {
  id: string;
  self: string;
  preview: string;
  livePreview: string;
  refreshingLivePreview?: boolean;
  foregroundType?: string;
  modelsVersion?: string;
  orientation?: ImageOrientation;
  width?: number;
  height?: number;
  previewWidth?: number;
  previewHeight?: number;
  hdWidth?: number;
  hdHeight?: number;
  "50mpWidth"?: number;
  "50mpHeight"?: number;
  fullAvailable?: boolean;
  ai_brush_processing_full_count: number;
  ai_brush_iterations: IterationHistory | null;
  ai_brush_last_iteration: string;
  last_focus_point?: Point;
  last_pointer_position?: Point;
  has_no_foreground?: boolean;
  sizeMb?: number;
};

export type Upload = {
  state: UploadState;
  file?: File;
  fileName?: string;
  url?: string;
  preview?: string;
  trustTokenData?: string;
  token?: string;
  status?: string;
  errorMessage?: string;
  captchaRequired?: boolean;
  captchaConfig?: Object;
};

export interface Image {
  meta: ImageMetaData;
  original: Upload | UploadError;
  previewResult?: ResultData | ProcessingError;
  hdResult?: ResultData | ProcessingError;
  magicBrushPreviewResult?: ResultData | ProcessingError;
  magicBrushHdResult?: ResultData | ProcessingError;
}

export interface Background {
  id: string;
  thumbnail_url: string;
  image_url: string;
  url: string;
  photographer: string;
  avg_color?: string;
}

export async function extractLayers(image: Image, variant: ResultVariant, persitentStore?: any): Promise<Image> {
  const result: ResultData | ProcessingError = getNestedKey(image, variant) as ResultData | ProcessingError;

  const isMagicBrushResult = variant === ResultVariant.MagicBrushPreview || variant === ResultVariant.MagicBrushHd;

  if (result.state === ProcessingState.Error && !isMagicBrushResult) {
    return Promise.reject(image);
  }

  let resultUrl = result.url;

  if (isMagicBrushResult || !!persitentStore.magicBrushCache) {
    const iterationId = persitentStore.magicBrushCache.iterationId;
    const iterationHistory = image.meta.ai_brush_iterations;
    const iteration = iterationHistory[iterationId];

    if (variant === ResultVariant.MagicBrushPreview) {
      resultUrl = iteration.preview;
    } else {
      resultUrl = iteration.hd;
    }
  }

  const response = await fetch(resultUrl);
  const zipFile = await response.arrayBuffer();

  const [zipCanvas, shadowZipCanvas, semitransparencyZipCanvas] = await ZipCanvas.fromBinary(image, zipFile);

  const layers: ResultLayers = {
    foreground: await zipCanvas.export(),
    shadow: await shadowZipCanvas.export(),
    semiTransparency: await semitransparencyZipCanvas.export(),
  };

  zipCanvas.dispose();
  shadowZipCanvas.dispose();
  semitransparencyZipCanvas.dispose();

  result.layers = layers;
  image[variant] = result;

  return Promise.resolve(image);
}

export function thumbnailUrl(image: Image): string {
  const upload: Upload = image.original;
  const previewResult: ResultData = image.previewResult as ResultData;

  // Inital upload failed, there is nothing that we can display
  // that would be releated to the actual image so we use a blank palceholder
  if (upload.state === UploadState.Error) {
    return thumbnailPlaceholder;
  }

  // If neither the preview nor the url are available, also return the placeholder
  if (!upload.preview && !upload.url) {
    return thumbnailPlaceholder;
  }

  // If the preview is available, use it
  if (previewResult.state === ProcessingState.Ready) {
    return previewResult.url;
  }

  // Fall back to the origininal image unitl a better preview is available
  return upload.url;
}

export function originalUrl(image: Image): string {
  const upload: Upload = image.original;
  const meta: ImageMetaData = image.meta;

  // Inital upload failed, there is nothing that we can display
  // that would be releated to the actual image so we use a blank palceholder
  if ([UploadState.Error, UploadState.Pending].includes(upload.state)) {
    return originalPlaceholder;
  } else if (upload.state === UploadState.Uploading) {
    return meta.preview;
  }

  return upload.url;
}

export const hasMagicBrushError = (image) => {
  // error with magic brush but we can continue
  if (!image) return false;

  const previewCheck = image.magicBrushPreviewResult.state === ProcessingState.Error;
  const hdCheck = image.magicBrushHdResult.state === ProcessingState.Error;

  return previewCheck || hdCheck;
};

export const getMagicBrushError = (image: Image) => {
  if (image.magicBrushPreviewResult.state === ProcessingState.Error) {
    return image.magicBrushPreviewResult as ProcessingError;
  }
  if (image.magicBrushHdResult.state) {
    return image.magicBrushHdResult as ProcessingError;
  }
};

// Delete previously extracted layers and update the image
export const clearLayers = (image: Image, variant: ResultVariant) => {
  const result: ResultData | ProcessingError = getNestedKey(image, variant) as ResultData | ProcessingError;

  if (result.state === ProcessingState.Error) {
    return image;
  }

  result.layers = undefined;
  image[variant] = result;
  return image;
};

export const hasExceededMagicBrushIterations = (image: Image, refreshTippy: Function) => {
  const iterations = image?.meta?.ai_brush_processing_full_count || 0;
  refreshTippy();
  return iterations >= MAX_MAGIC_BRUSH_ITERATIONS;
};

export const hasMagicBrushHdResult = (image: Image, iteration: string): boolean => {
  return image.meta.ai_brush_iterations[iteration].hasOwnProperty("hd");
};

export const createPlainBackgroundObjectFromUrl = (image_url: string): Background => {
  return {
    image_url,
    thumbnail_url: image_url,
    id: image_url,
    photographer: null,
    url: null,
  };
};
