<template>
  <div
    v-for="i in props.amountOfTiles"
    :key="i"
    :class="['!rounded-lg !bg-secondary', animateClass(i), props.additionalClasses]"
  ></div>
</template>

<script setup lang="ts">
interface LoadingPlaceholderProps {
  additionalClasses: string;
  amountOfTiles: number;
}

const props = defineProps<LoadingPlaceholderProps>();

const animateClass = (i: number) => {
  if (!i) return "animate-pulse";

  if (i % 2 == 0) return "animate-pulse-1";
  if (i % 3 == 0) return "animate-pulse-2";
  return "animate-pulse";
};
</script>

<style scoped>
@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes pulse-1 {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes pulse-2 {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.animate-pulse-1 {
  animation: pulse-1 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.animate-pulse-2 {
  animation: pulse-2 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>
