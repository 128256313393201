<template>
  <div class="attribution-gradient-cover flex items-center px-4">
    <a
      :href="props.attribution.sourceUrl"
      class="text-typo-secondary text-xs w-full text-white font-semi-bold cursor-pointer text-decoration-none"
      style="z-index: 100"
      target="_blank"
    >
      {{
        I18n.t("image.background_attribution", {
          author: props.attribution.photographer,
        })
      }}
    </a>
  </div>
</template>
<script setup lang="ts">
import "@/src/i18n";

interface BackgroundAttributionProps {
  isBackgroundLoading: boolean;
  attribution: {
    photographer: string;
    sourceUrl: string;
  };
}
const props = defineProps<BackgroundAttributionProps>();
</script>
<style lang="scss" scoped>
.attribution-gradient-cover {
  width: 100%;
  height: 3.5rem;
  position: absolute;

  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 33%, rgba(0, 0, 0, 0.65) 100%);

  @media (max-width: 768px) {
    height: 2.5rem;
    top: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 33%, rgba(0, 0, 0, 0.65) 100%);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  @media (min-width: 768px) {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    bottom: 0;
  }
}
</style>
