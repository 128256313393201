<template>
  <div id="maintenanceModeBanner" class="fixed w-full top-0 z-60">
    <Alert :dismissable="true" class="maintenance-mode-alert rounded-none" :onClose="close">
      <span v-html="props.message"></span>
    </Alert>
  </div>
</template>

<script setup lang="ts">
import "@/src/i18n";
import { onMounted } from "vue";
import { Alert } from "prism";
import emitter from "@/modules/event_bus";

const props = defineProps({
  message: String,
});

onMounted(() => {
  emitter.emit("maintenanceModeBannerVisibilityChanged", true);
});

const close = async () => {
  emitter.emit("maintenanceModeBannerVisibilityChanged", false);

  // await fetch(Routes.get("session_preferences_url"), {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     "X-CSRF-Token": Rails.csrfToken(),
  //   },
  //   body: JSON.stringify({
  //     key: "maintenance_mode_banner_dismissed",
  //     value: true,
  //   }),
  // });
};
</script>

<style>
.z-60 {
  z-index: 60;
}

.maintenance-mode-alert {
  background-color: black !important;
  color: white !important;
}

.maintenance-mode-alert.rounded-none {
  border-radius: 0 !important;
}

.maintenance-mode-alert a {
  color: white !important;
  font-weight: bold !important;
  text-decoration: none !important;
}

.maintenance-mode-alert button {
  color: white !important;
}

.maintenance-mode-alert .flex-none.w-6.h-6:first-of-type {
  display: none;
}
</style>
