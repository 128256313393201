<template>
  <div>
    <div v-if="!store.isBackgroundSearchActive" class="!m-0 divider py-3"><hr class="!m-0" /></div>
    <div :class="searchBoxClasses" class="flex items-center">
      <Button
        v-if="store.isBackgroundSearchActive"
        variant="outline"
        size="default"
        :on-click="closeSearch"
        class="mr-2"
      >
        <template #start>
          <ChevronLeftIcon />
        </template>
      </Button>
      <Button
        v-if="store.isCollectionSelected && store.isBackgroundSearchActive"
        variant="outline"
        size="compact"
        as="button"
        @click="store.clearCollectionResults()"
      >
        {{ I18n.t(`editor.background_image_search.collections.${store.selectedCollection.title}`) }}
        <template #end>
          <CloseIcon />
        </template>
      </Button>

      <SearchInput
        v-else
        :placeholder="I18n.t('editor.search')"
        :is-search-active="store.isBackgroundSearchActive"
        v-model:searchString="searchString"
        :badgeText="capitilizeString(I18n.t('misc.new_badge'))"
        :submit-disabled="searchString.length < 2"
        @update:searchString="handleUpdateSearchString"
        @submit="search"
        @focus="startSearch"
        @back="closeSearch"
        @clear="clearSearch"
        class="flex-1"
      />
    </div>

    <div v-if="store.isBackgroundSearchActive" class="divider"><hr /></div>

    <div v-if="hasError && store.isBackgroundSearchActive" class="error-container h-48 sm:h-64 text-center">
      <span class="text-sm text-typo-secondary">
        {{ errorMessageLocalized }}
      </span>
      <a
        v-if="error.state === BackgroundImageSearchErrorState.FeatureUnavailable"
        @click="closeSearch"
        class="d-block font-bold pt-3 text-sm text-primary hover:text-primary-dark focus:outline-none"
      >
        {{ I18n.t("editor.background_image_search.error.close") }}
      </a>
      <div v-else-if="error.state === BackgroundImageSearchErrorState.NoResults">
        <span class="text-sm text-typo-secondary">
          {{ I18n.t("editor.background_image_search.error.try_again") }}
        </span>

        <Collections
          :collections="collections"
          :foreground-image-id="store.selectedImage.meta.id"
          @selectCollection="collectionBackgrounds"
          class="pt-3"
        />
      </div>
    </div>

    <div v-else class="md:!pb-4 scroll-container pt-2">
      <div v-if="areCollectionsVisible">
        <div id="browse-collections-message" class="text-center pb-3">
          <span class="text-sm text-typo-secondary">
            {{ I18n.t("editor.background_image_search.browse_collections") }}
          </span>
        </div>
        <Collections
          :collections="collections"
          :foreground-image-id="store.selectedImage.meta.id"
          @selectCollection="collectionBackgrounds"
        />
      </div>

      <div class="tile-grid-photos gap-1">
        <PhotoTiles
          v-if="store.isBackgroundSearchActive"
          :selected-image-state="props.selectedImageState"
          :available-backgrounds="parseBackgroundImageSearchResult(store.backgroundsSearchResults, isMobile)"
          :is-loading="isLoading"
          :is-search-active="store.isBackgroundSearchActive"
          image-button-size="lg"
        />

        <PhotoTiles
          v-else
          :selected-image-state="props.selectedImageState"
          :available-backgrounds="availableBackgrounds"
          :is-loading="isLoading"
          :is-search-active="false"
          image-button-size="lg"
        />
      </div>
      <div ref="infiniteScrollTrigger" />
      <div class="pb-3"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PersistentStore } from "@/stores/persistent_store";
import PhotoTiles from "../../prism/photo_tiles.vue";
import { SearchInput, Button, CloseIcon, ChevronLeftIcon } from "prism";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import Collections from "./collections.vue";
import {
  parseBackgroundImageSearchResult,
  BackgroundImageSearchErrorState,
  BackgroundImageCollection,
} from "@/modules/internal_api/background_search";
import Client from "@/modules/internal_api/client";
import { useEditorStore } from "@/stores/editor_store";
import { capitilizeString } from "@/modules/utils";
import { rbgEditorApplyBackgroundImageV102, rbgEditorUploadBackgroundImageV100 } from "kaleido-event-tracker";
import { useBackgroundSearch } from "@/composables/bg_search";
import { useMonitorSize } from "@/composables/monitor_size";
import { createPlainBackgroundObjectFromUrl } from "@/modules/internal_api/image";

interface BackgroundImageSelectorProps {
  selectedImageState: PersistentStore;
  foregroundType: string;
}

const props = defineProps<BackgroundImageSelectorProps>();
const store = useEditorStore();
const searchString = ref(store.searchTerm);

const {
  isLoading,
  error,
  availableBackgrounds,
  collections,
  loadRelevantBackgrounds,
  loadRelevantCollections,
  fetchBackgrounds,
  fetchCollectionBackgrounds,
} = useBackgroundSearch(props.foregroundType);
const { isMobile } = useMonitorSize();

const infiniteScrollTriggerOffset = 20;
const pageLimit = 10;
const perPage = 15;
const scrollContainer = ref<HTMLElement | null>(null);
const infiniteScrollTrigger = ref<HTMLElement | null>(null);

let observer: IntersectionObserver | null = null;

const hasError = computed(() => error.value != null);
const errorMessageLocalized = computed(() => {
  return I18n.t(`editor.background_image_search.error.${error.value?.state}`);
});

const selectCollection = (collection: BackgroundImageCollection) => {
  store.selectCollection(collection);
};

const areCollectionsVisible = computed(
  () => store.isBackgroundSearchActive && !store.isCollectionSelected && store.isSearchTermEmpty && !isLoading.value
);

const startSearch = () => {
  store.turnSearchOn();
};

const closeSearch = () => {
  store.turnSearchOff();
};

const clearSearch = () => {
  searchString.value = "";
  store.clearSearchTerm();
};

onMounted(() => {
  store.initSearch();

  observer = new IntersectionObserver(
    async ([entry]) => {
      const currentPage = store.currentSearchResultsPage;
      const nextPage = currentPage + 1;
      if (entry.isIntersecting && store.isBackgroundSearchActive && pageLimit > currentPage && !isLoading.value) {
        const isLimitReached = store.backgroundsSearchResults.length % perPage !== 0;
        if (isLimitReached) {
          return;
        }

        if (!store.isSearchTermEmpty) {
          const results = await fetchBackgrounds(store.searchTerm, nextPage);
          store.loadMoreSearchResults(results, nextPage);
        } else if (store.isCollectionSelected) {
          const results = await fetchCollectionBackgrounds(store.selectedCollection, nextPage);
          store.loadMoreSearchResults(results, nextPage);
        }
      }
    },
    {
      root: scrollContainer.value,
      rootMargin: `0px 0px ${infiniteScrollTriggerOffset * 100}px 0px`,
      threshold: 0.1,
    }
  );

  if (infiniteScrollTrigger.value) {
    observer.observe(infiniteScrollTrigger.value);
  }

  loadRelevantBackgrounds();
  loadRelevantCollections();
});

onBeforeUnmount(() => {
  if (observer && infiniteScrollTrigger.value) {
    observer.unobserve(infiniteScrollTrigger.value);
    observer.disconnect();
  }
  closeSearch();
});

const handleUpdateSearchString = (value: string) => {
  searchString.value = value;
};

const search = async (e: { target: { value: string } }) => {
  store.clearSearchResults();
  const results = await fetchBackgrounds(searchString.value, 1);
  store.startSearch(searchString.value, results || []);
};

const collectionBackgrounds = async (collection: BackgroundImageCollection, page: number = 1) => {
  selectCollection(collection);
  const results = await fetchCollectionBackgrounds(collection, page);
  store.loadMoreSearchResults(results || [], page);
};

const uploadBackgroundFile = async (file: File) => {
  store.isUploadingCustomBackground = true;

  const result = await Client.uploadBackgroundToBucket(store.selectedImage, file);

  if (result.success) {
    props.selectedImageState.withSnapshot(() => {
      props.selectedImageState.setSelectedBackgroundColor(undefined);
      props.selectedImageState.setUploadedBackgroundPhotoUrl(result.url);
      props.selectedImageState.setSelectedBackgroundPhoto(createPlainBackgroundObjectFromUrl(result.url));
      props.selectedImageState.setIsBackgroundAddedByBlur(false);
    });

    rbgEditorUploadBackgroundImageV100({ image_id: store.selectedImage.meta.id });
    rbgEditorApplyBackgroundImageV102({
      image_id: store.selectedImage.meta.id,
      background_image_id: result.url,
      source: "user",
    });
  }

  store.isUploadingCustomBackground = false;
};

window.uploadBackgroundFile = uploadBackgroundFile;

const searchBoxClasses = computed(() => {
  return {
    "pb-3": !store.isBackgroundSearchActive,
  };
});

const scrollContainerHeight = computed(() => {
  return store.isBackgroundSearchActive ? "26rem" : "22.5rem";
});
const scrollHeight = computed(() => {
  return store.isSearchActive ? "18rem" : "14.5rem";
});
</script>

<style lang="scss" scoped>
.tile-grid-photos {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding: 0 0.25rem;

  @media (min-width: 376px) and (max-width: 767px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@-moz-document url-prefix() {
  .tile-grid-photos {
    padding-right: 1rem;
  }
}

.scroll-container {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: v-bind(scrollContainerHeight);

  @media (max-width: 768px) {
    height: v-bind(scrollHeight);
  }
}
/* this feature is only supported on iOS, it's used here because scroll behavior is different on iOS and negative margin shifts the content */
@supports not (-webkit-touch-callout: none) {
  .scroll-container {
    margin-right: -1rem;
  }
}

.scroll-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1rem;
}

.scroll-container::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.divider {
  margin: 0 -16px !important;

  @media (min-width: 640px) {
    margin: 0 -24px !important;
  }
}

.error-container {
  max-width: 260px;
  min-width: 100%;
  @media (max-width: 768px) {
    height: 15.5rem;
  }
}
</style>
